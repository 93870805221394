import React, { useState } from 'react';
import styles from './ClipCard.module.scss';
import defaultRadioKeyframe from '../../../assets/radio.jpg';
import defaultKeyframe from '../../../assets/defaultThumbnail.png';
import CountryFlag from 'src/Widgets/common/CountryFlag/CountryFlag';
import { highlightChannelName } from 'src/Widgets/common/MentionResults/MentionCard/MentionCard';
import useSentimentIcon from '../hooks/useSentimentIcon';
import { faClipboard, faCopy, faEye, faPlay} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppSelector } from 'src/redux/hooks';
import { useTranslation } from 'react-i18next';

interface ClipCardProps {
    clip: any;
    clipDetailData: any;
    style: any;
    isEdited?: boolean;
    activeFilter: any;
    isMentionFindrApp: boolean;
    backFillSelectionVisible: boolean;
    setSelectedCardElements: any;
    selectedCardElements?: any;
}

const ClipCard = ({
    clip,
    clipDetailData,
    style,
    isEdited,
    activeFilter,
    isMentionFindrApp,
    backFillSelectionVisible,
    setSelectedCardElements,
    selectedCardElements
}: ClipCardProps) => {
    const [showCopiedMessage, setShowCopiedMessage] = useState(false);
    const meResponse = useAppSelector((state) => state.me);

    const sentimentIcon = useSentimentIcon(clip.sentiment);
    const {t} = useTranslation();

    const programTitle =
        clipDetailData.program?.length > 50
            ? clipDetailData.program?.substring(0, 50) + '...'
            : clipDetailData.program;

    const description =
        clipDetailData?.desc.length > (isEdited ? 200 : 400)
            ? clipDetailData.desc.substring(0, isEdited ? 200 : 400) + '...'
            : clipDetailData.desc;

    const isArabic = /[\u0600-\u06FF]/.test(description);

    const copyToClipboard = (dataToCopy: any) => {
        navigator.clipboard.writeText(dataToCopy);
        setShowCopiedMessage(true);
        setTimeout(() => {
            setShowCopiedMessage(false);
        }, 1000);
    };

    const isDuplicate = (clip: any) => clip.dupType !== null && !activeFilter.foldEnabled;

    const handleCheckboxClick = (e: React.MouseEvent<HTMLInputElement>) => {
        e.stopPropagation();
        const isChecked = (e.target as HTMLInputElement).checked;
        if (isChecked) {
            setSelectedCardElements([...selectedCardElements, clipDetailData]);
        } else {
            setSelectedCardElements(selectedCardElements.filter((item: any) =>   item.notifID
            ? item.notifID !== clipDetailData.notifid
            : item.notifid !== clipDetailData.notifid));
        }
    };

    const handleCardClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (backFillSelectionVisible) {
            const isChecked = selectedCardElements.some((item: any) =>
                item.notifID ? item.notifID === clipDetailData.notifid : item.notifid === clipDetailData.notifid);
            setSelectedCardElements(
                isChecked
                    ? selectedCardElements.filter((item: any) => 
                        item.notifID ? item.notifID !== clipDetailData.notifid : item.notifid !== clipDetailData.notifid)
                    : [...selectedCardElements, clipDetailData]
            );
        } else {
            window.open(clipDetailData.viewLink, '_blank');
        }
    };

    return (
        <div
            style={style}
            key={clip.channelid}
            className={styles.clipCardContainer}
            onClick={handleCardClick}
        >
            {backFillSelectionVisible && (
                <input
                    type="checkbox"
                    className={styles.backFillCheckbox}
                    checked={selectedCardElements.some((item: any) =>
                        item.notifID
                            ? item.notifID === clipDetailData.notifid
                            : item.notifid === clipDetailData.notifid
                    )}
                    onClick={handleCheckboxClick}
                    onChange={(e) => e.stopPropagation()}
                />
            )}
            {isDuplicate(clip) && (
                <div
                    title={'This is a duplicate clip of the original clip.'}
                    className={`${styles.duplicateIcon} ${
                        isArabic ? styles.duplicateIconRTL : ''
                    }`}
                >
                    <FontAwesomeIcon icon={faCopy} />
                </div>
            )}
            <img
                width={5}
                src={
                    clipDetailData.mediatype === 'radio'
                        ? defaultRadioKeyframe
                        : clipDetailData.kfLink
                }
                alt="clip thumbnail"
                onError={(e: any) => {
                    e.target.src = defaultKeyframe;
                }}
                className={styles.clipKeyframe}
            />
            <FontAwesomeIcon
                icon={faPlay}
                className={
                    backFillSelectionVisible ? styles.playIconBackFill : styles.playIcon
                }
                onClick={(e) => {
                    e.stopPropagation();
                    window.open(clipDetailData.viewLink, '_blank');
                }}
            />
            <div className={styles.clipTitleContainer}>
                {isEdited && sentimentIcon && (
                    <span className={styles.sentimentIcon}>{sentimentIcon}</span>
                )}
                {
                    <span className={styles.copyCardDataIcon}>
                        <FontAwesomeIcon
                            icon={faClipboard}
                            onClick={(e) => {
                                e.stopPropagation();
                                copyToClipboard(clipDetailData.desc);
                            }}
                        />
                    </span>
                }
                {/* <hr className={styles.cardHr}/> */}
                <span
                    className={styles.clipTitle}
                    style={isMentionFindrApp ? { fontSize: '0.8rem' } : {}}
                >
                    <div className={styles.timeCodeChannel}>
                        <img
                            src={clipDetailData.icon}
                            width="40"
                            height="20"
                            alt="icon"
                        />
                        <span className={styles.channelName}>
                            {highlightChannelName(
                                clip.channelName,
                                clipDetailData.channel
                            )}
                        </span>
                        <span>{clipDetailData?.beginF}</span>
                        {clip.headline && ' - '}
                        {programTitle && ' - '}
                        <span className={styles.programTitle}>
                            {programTitle}
                            {programTitle && clip.summary && ' - '}
                            {isEdited && (
                                <div
                                    className={styles.summaryHeadlineContainer}
                                    title={clip.headline}
                                >
                                    <span className={styles.editedHeadline}>
                                        {clip.headline
                                            ? clip.headline.slice(0, 95) +
                                              (clip.headline.length > 95 ? '...' : '')
                                            : ''}
                                    </span>
                                    {/* {programTitle && clip.summary && clip.headline && ' - '} */}
                                </div>
                            )}
                        </span>
                        {meResponse.can_AVE === '1' && clipDetailData.aveReach && (
                            <span className={styles.ave}>{` ${
                                clipDetailData.clipDuration
                            } sec | ${Math.trunc(
                                clipDetailData.clipDuration * clipDetailData.aveRate
                            )} ${clipDetailData.aveCurrency || ''} | ${Math.trunc(
                                clipDetailData.aveReach
                            )}`} <FontAwesomeIcon icon={faEye}/></span>
                        )}
                    </div>
                    <div className={styles.flagContainer}>
                        <CountryFlag cc={clip.cc} width={20} />
                    </div>
                </span>
                {isEdited && (
                    <div className={styles.summaryHeadlineContainer}>
                        <span className={styles.editedSummary} title={clip.summary}>
                            {clip.summary
                                ? clip.summary.slice(0, 400) +
                                  (clip.summary.length > 400 ? '...' : '')
                                : null}
                        </span>
                    </div>
                )}

                {isEdited && <hr className={styles.cardHr} />}

                <span
                    className={isArabic ? styles.clipDescArabic : styles.clipDesc}
                    title={clipDetailData.desc}
                    dangerouslySetInnerHTML={{
                        __html: description
                    }}
                ></span>
                {showCopiedMessage && (
                    <div className={styles.copiedMessage}>
                        <span>
                            {isEdited ? 'Summary' : 'Description'} {t('copied to clipboard')}
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ClipCard;
