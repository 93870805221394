import React, { useCallback, useState } from 'react';
import APIClips from 'src/API/APIClips';
import { dateToString } from 'src/Widgets/common/helpers';
import { MentionsVisualizerTypes } from '../MentionsVisualizer.types';
import APIProfileGroups from 'src/API/APIProfileGroups';
import config from 'src/config/config';
import { getProfileGroups } from 'src/redux/actions/actions.profileGroups';
import { useAppDispatch } from 'src/redux/hooks';
import APIProfiles from 'src/API/APIProfiles';

const useFetchClips = (
    selectedDateRange: MentionsVisualizerTypes.DateRange,
    searchGroupExists: boolean,
    searchProfileGroup: MentionsVisualizerTypes.ProfileGroupNative | null | undefined
) => {
  
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<MentionsVisualizerTypes.Clip[]>([]);

    const dispatch = useAppDispatch();

    const fetchClips = async (profileIds: string[], dateRange = selectedDateRange) => {
        try {
            setLoading(true);
            const clipPromises = profileIds.map((profileId) =>
                APIClips.getClips(
                    profileId,
                    dateToString(dateRange.startDate),
                    dateToString(dateRange.endDate)
                )
            );
            const clipResponses = await Promise.all(clipPromises);
            const allClips = clipResponses.flatMap((res) => res.data);
            const allSuccessful = clipResponses.every((res) => res.rc === 0);
            if (allSuccessful) {
                setData(allClips);
                setLoading(false);
            } else {
                console.error('Error: Not all clips were fetched successfully');
            }
        } catch (error) {
            console.error('Error fetching clips:', error);
        }
    };

    const fetchEditedClips = async (profileIds: string[]) => {
        try {
            setLoading(true);
            const clipPromises = profileIds.map((profileId) =>
                APIClips.getEditedClips(
                    profileId,
                    dateToString(selectedDateRange.startDate),
                    dateToString(selectedDateRange.endDate)
                )
            );
            const clipResponses = await Promise.all(clipPromises);
            const allClips = clipResponses.flatMap((res) => res.data);
            setData(
                allClips.filter((clip) => {
                    return Object.keys(clip).length > 0;
                })
            );
        } catch (error) {
            console.error('Error fetching clips:', error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 300);
        }
    };

    const fetchSearchQueryClips = useCallback(
        async (searchQuery: string, selectedDateRange: MentionsVisualizerTypes.DateRange) => {
            let res;

            try {
                setLoading(true);
                if (!searchGroupExists) {
                    res = await APIProfileGroups.post({
                        title: config.searchQueryGroupTitle
                    });
                } else {
                    res = searchProfileGroup;
                }

                if ((res && res.rc === 0) || searchGroupExists) {
                    const searchProfileToCreate = {
                        title: searchQuery,
                        queries: {
                            '**': {
                                standardQuery: {
                                    query: searchQuery
                                },
                                superQuery: []
                            }
                        },
                        isSuper: false,
                        isEnabled: true,
                        isSearchAgent: true,
                        notifyIM: false,
                        notifyEmail: 0,
                        profileGroupId: res.id,
                        channelGroupId: null,
                        shareGroups: [],
                        updatedShareGroups: [],
                        logos: [],
                        initShareGroups: [],
                        hasQuery: true
                    };

                    // Check if the search query already exists in profiles only if the search group exists
                    const existingProfile : any =
                        searchProfileGroup &&
                        searchProfileGroup.profiles.find(
                            (profile: MentionsVisualizerTypes.ProfileTypes) => profile.title === searchQuery
                        );

                    if (existingProfile) {
                        // Profile exists, fetch clips
                        const searchCall = await APIClips.getSearchQueryClips(
                            existingProfile.id,
                            dateToString(selectedDateRange.startDate),
                            dateToString(selectedDateRange.endDate)
                        );
                        if (searchCall.rc === 0) {
                            fetchClips([existingProfile.id + 't'], selectedDateRange);
                            dispatch(getProfileGroups());
                        }
                    } else {
                        if (
                            searchProfileGroup &&
                            searchProfileGroup.profiles.length >= 10
                        ) {
                            // Update the first profile if there are already 10 profiles
                            const firstProfile : any = searchProfileGroup.profiles[0 as any];
                            const firstProfileDetails = await APIProfiles.get(
                                firstProfile.id
                            );
                            if (firstProfileDetails.rc === 0) {
                                const queryId =
                                    firstProfileDetails.data.queries['**'].standardQuery
                                        .id;
                                const updateRes = await APIProfiles.put(firstProfile.id, {
                                    ...searchProfileToCreate,
                                    queries: {
                                        '**': {
                                            standardQuery: {
                                                id: queryId,
                                                query: searchQuery
                                            },
                                            superQuery: []
                                        }
                                    },
                                    id: firstProfile.id
                                });
                                if (updateRes.rc === 0) {
                                    dispatch(getProfileGroups());
                                    const searchCall = await APIClips.getSearchQueryClips(
                                        firstProfile.id,
                                        dateToString(selectedDateRange.startDate),
                                        dateToString(selectedDateRange.endDate)
                                    );
                                    if (searchCall.rc === 0) {
                                        fetchClips([firstProfile.id + 't'], selectedDateRange);
                                    }
                                }
                            }
                        } else {
                            // Create a new profile if there are less than 10 profiles
                            const createRes = await APIProfiles.post(
                                searchProfileToCreate
                            );
                            if (createRes.rc === 0) {
                                dispatch(getProfileGroups());
                                const searchCall = await APIClips.getSearchQueryClips(
                                    createRes.data.id,
                                    dateToString(selectedDateRange.startDate),
                                    dateToString(selectedDateRange.endDate)
                                );
                                if (searchCall.rc === 0) {
                                    fetchClips([createRes.data.id + 't'], selectedDateRange);
                                }
                            }
                        }
                    }
                }
            } catch (error) {
                console.error('Error fetching clips:', error);
            } finally {
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            }
        },
        [searchGroupExists, searchProfileGroup, selectedDateRange, dispatch]
    );

    return { loading, data, fetchClips, fetchEditedClips, fetchSearchQueryClips };
};

export default useFetchClips;
