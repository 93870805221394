import { setError } from '../redux/actions/actions.error';
import config from 'src/config/config.js';
import APITelemetry from './APITelemetry';
import { deleteCookie, getCookie, getResponseHeader, sleep } from 'src/Widgets/common/helpers';
import { setSessionExpired } from 'src/redux/actions/actions.auth';
import qs from 'qs';
import { convertUrlToGeneric, endpoints } from './endpoints';

class GenericCalls {
    static async get(url, params, callSettings, hideErrors) {
        if(params !== undefined) {
            if(Array.isArray(params)){
                params = params.map((p,i)=> (`${Object.keys(p)[0]}=${encodeURIComponent(p[Object.keys(p)[0]])}`));
            } else {
                params = Object.keys(params).map((key)=>`${key}=${encodeURIComponent(params[key])}`)
            }
            url = `${url}?${params.join('&')}`;
        }
        return this.call('GET', url, undefined, callSettings, hideErrors);
    }

    static async post(url, data, callSettings, hideErrors, reportTelemetry) {
        return this.call('POST', url, data, callSettings, hideErrors, reportTelemetry);
    }

    static async put(url, data, callSettings, hideErrors) {
        return this.call('PUT', url, data, callSettings, hideErrors);
    }

    static async delete(url, hideErrors) {
        return this.call('DELETE', url, undefined, undefined, hideErrors);
    }

    static async call(method, url, data, callSettings = {
        contentType: undefined,
        responseType: undefined,
        authorization: undefined,
        abortSignal: undefined,
        returnResponse: undefined,
    }, hideErrors, reportTelemetry) {
        let headers = new Headers();
        // TODO: change "window.localStorage" back to "window.STORAGE" if found out how to get working with testing setup
        const googleAuth = window.localStorage.getItem('googleAuth');
        if (callSettings.contentType) {
            headers.append('Content-Type', callSettings.contentType);
            if (callSettings.contentType === 'application/x-www-form-urlencoded; charset=utf-8') {
                data = qs.stringify(data);
            } else {
                data = JSON.stringify(data);
            }
        }
        else {
            // switch(method){
            //     case 'PUT':
            //     case 'POST':
            //         headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8');
            //         data = qs.stringify(data);
            //         break;
            //     default:
                    data = JSON.stringify(data);
                    headers.append('Content-Type', 'application/json; charset=utf-8');
            //         break;
            // }
        }

        if (callSettings.authorization) {
            headers.append('Authorization', callSettings.authorization);
        }
        else if (googleAuth){
            // headers.append(
            //     'Authorization',
            //     'Bearer ' + googleAuth
            // );
        }

        try {
            let response = await fetch(url, {
                method: method,
                body: data,
                headers: headers,
                signal: callSettings.abortSignal,
            });

            const varnishId = getResponseHeader(response, 'x-varnish');
            if (response.status !== 200) {
                let telemetryVarnishId;
                switch(response.status){
                    case 401:
                        // config.STORAGE.removeItem('isAuthenticated');
                        
                        // 'emmP' cookie tells us that we should have a valid session, but if we still get 401 it looks like the session is expired anyways for some reason (e.g. server side expiry?), so we delete the emmP cookie
                        deleteCookie('emmP');

                        const s = window.rxStore.getState();
                        if(!s.auth.session.isExpired){
                            window.rxStore.dispatch(setSessionExpired(true));
                        }
                        while (getCookie('emmP') === undefined) {
                            await sleep(500);
                        }
                        response = await this.call(...arguments);
                        // TODO: handle re-auth of google here - see bug #1226
                        return response;
                    default:
                        if (reportTelemetry !== false) {
                            const baseUrl = convertUrlToGeneric(url, endpoints);
                            const telemetryResp = await APITelemetry.post({
                                msg: `HTTP ${response.status} for ${method} ${baseUrl}`,
                                data: {url},
                                varnishId
                            });
                            telemetryVarnishId = getResponseHeader(telemetryResp, 'x-varnish');
                        }
                        break;
                    // case 503:
                    //     if (process.env.REACT_APP_ENV === 'development') {
                    //         alert(`HTTP ${response.status} for ${method} ${url}`)
                    //     }
                }
                console.log('Problem! Status Code: ' + response.status);
                let resp;
                try { resp = await response.json(); } catch(e) { }

                const out = 
                    { status: response.status
                    , statusText: response.statusText
                    , url: url
                    , data: resp
                    , method
                    , varnishId
                    , telemetryVarnishId
                    , fullUrl: response.url
                    }

                if(
                    (
                        !hideErrors
                        || (resp && Array.isArray(hideErrors) && !hideErrors.includes(resp.rc))
                    )
                    && response.status !== 401
                ) {
                    window.rxStore.dispatch(setError({data:out, reportTelemetry}));
                }
                return out;
            }
            if(callSettings.returnResponse){ return response; }  // in some cases we may want the full response (including headers), even if they do not fail - e.g. for telemetry calls, to get the received varnish-id

            let res;
            switch (callSettings.responseType) {
                case 'text': res = await response.text(); break;
                case 'blob': res = await response.blob(); break;
                default: res = await response.json(); break;
            }

            // TODO: what if res.rc !== 0?
            const out2 = 
                { status: response.status
                , statusText: response.statusText
                , url: url
                , data: res
                , method
                , varnishId
                , fullUrl: response.url
                }
            if(res.rc !== undefined && res.rc !== 0) {
                if(
                    !hideErrors
                    || (Array.isArray(hideErrors) && !hideErrors.includes(res.rc))) {
                        window.rxStore.dispatch(setError({data:out2, reportTelemetry}));
                    }
            }
            return res;
        } catch (err) {
            console.log('Fetch Error!', err, url);
            return { status: 0 };
        }
    }
}

export default GenericCalls;
