import React from 'react';
import countryFlagsBundle from './flagsBundle';

interface CountryFlagProps {
    cc: string;
    width: number;
}

const CountryFlag = ({ cc, width }: CountryFlagProps) => {
  // Assuming countryCode is a string like "AR"

  return (
      <img width={width} height={14} src={countryFlagsBundle[cc as keyof typeof countryFlagsBundle]} alt={`Flag of ${cc}`} />
  );
};

export default CountryFlag;
