import React from 'react';
import { connect } from 'react-redux';
import { clearError } from '../../../../redux/actions/actions.error';
import errorStyles from './ErrorBanner.module.scss';
import Popup from '../Popup/Popup';
import { withTranslation } from 'react-i18next';
import emma from '../../../../assets/emm-mascot-emma.svg';

const ErrorBanner = ({ theme, error, clearError, me, t }) =>{

  const prepareError = (e)=>{
    return {
      status: e.status && { 
        title:'HTTP Status Code', 
        val: `${e.status} (${e.statusText})` 
      },
      url: (e.method || e.fullUrl) &&{
        title: 'URL', 
        val: `${error.method} "${error.fullUrl}"`
      },
      varnishId: e.varnishId && {
        title: 'varnishId', 
        val: e.varnishId
      },
      telemetryVarnishId: e.telemetryVarnishId && {
        title: 'telemetryVarnishId', 
        val: e.telemetryVarnishId
      },
      returnCode: error.data && {
        title: 'returnCode', 
        val: error.data.rc
      },
      msg: error.data && {
        title: 'msg', 
        val: error.data.msg
      },
    }
  }

  const renderErrorHtml = (error)=>{
    return (
        <div style={{maxWidth:'100%'}}>
          {Object.keys(error).map(key=>{
            let e = error[key];
            return (
              e && <div style={{maxWidth:'100%'}} key={key}>
                {error[key].title}: {error[key].val}
              </div>
            )
          })}
        </div>
      )
  }

  const renderErrorText = (error)=>{
    return (
          Object.keys(error).map(key=>{
            let e = error[key];
            return (
              e && 
                `${error[key].title}: ${error[key].val}`
            )
          }).filter(e=>e!==undefined).join('\n')
      )
  }
  
  let e, text, emailText;
  if(error){
    e = prepareError(error);
    text = renderErrorText(e);
    emailText = encodeURIComponent('\n\nError Details:\n' + text);
  }

  return error && <>
    {/* <Banner
      faIcon={faTimesCircle}
      className={classNames(
        theme.backgroundDanger,
        {[styles.hidden]: !error}
      )}
      onClose={clearError}
  >
      {renderErrorHtml(e)}
    </Banner> */}
    <Popup
      wrapperStyle={{zIndex: 1000000}}
      size='auto'
      onCancel={clearError}
      cancelTxt={t('Close')}
    >
      <div className={errorStyles.wrapper}>
        <h2>{t('unexpected_error_occured')}</h2>
        <div className={errorStyles.explanation}>
          <div className={errorStyles.explanationText}>
            <div style={{marginBlockEnd:'1rem'}}>{`${t('oops_our_fault')} `}</div>
            <div>
              {error.telemetryVarnishId && `${t('email_operations_sent')} `}
              {`${t('contact_2ndlevel')} `} 
              {me && me.support &&
                <a href={`mailto:${me.support}?subject=&body=${emailText}`}>
                  {me.support}
                </a>
              }
              <div style={{marginBlockStart:'1rem'}}>
                varnishID: {e?.varnishId?.val}
              </div>
            </div>
          </div>
          <div className={errorStyles.ourFault}>
            { <img src={emma} /> }
            
          </div>
        </div>
        <div
          className={errorStyles.details}
          // style={{cursor:'pointer'}}
          // onClick={()=>{window.navigator && window.navigator.clipboard && window.navigator.clipboard.writeText(text)}}
          // title={t('copy_clipboard')}
        >
          <b>{t('error_details')}:</b>
          {renderErrorHtml(e)}
        </div>
      </div>
    </Popup>
  </>
}

const mapStateToProps = state => ({ error: state.error, theme: state.theme, me: state.me });
export default connect(mapStateToProps, { clearError })(withTranslation()(ErrorBanner));